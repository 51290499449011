import React, { useState, useEffect, useContext } from "react";
import {
  Ballot,
  Header,
  Footer,
  MyPDF2,
  EmailSignup,
  LineSplit,
  VoterGuide,
  GoalMeter,
  Device,
  Calendar,
  AlertSignup
} from "../components";
import { useParams, useLocation, Link } from "react-router-dom";
import config from "../lib/config";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import "./views.styles.scss";
import { GlobalContext } from "../context/GlobalState";
import client from "../sanityClient";
import { Image, Button, Row, Col, Container } from "react-bootstrap";
import { iconWarning } from "../lib/images";
import { iconHandRightWhite } from "../lib/images";

function BallotPage() {
  const [races, setRaces] = useState([]);
  const [amendments, setAmendments] = useState([]);
  const { state, addItemToList, resetGlobalState } = useContext(GlobalContext);
  const [noRace, setNoRace] = useState(false);
  let { cd, sldl, sldu, county, countyDistrict, schoolDistrict, municipality } = useParams();
  let location = useLocation();
  const { t, i18n } = useTranslation();
  let lang = i18n.language;

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  let query = useQuery();

  const pdfTranslations = {
    your_personalized: t("your_personalized"),
    voter_guide: t("voter_guide"),
    your_choice: t("your_choice"),
    no_endorsements: t("no_endorsements"),
    is_endorsed_by: t("is_endorsed_by"),
    is_endorsed_by_kr: t("is_endorsed_by_kr"),
    paid_for: t("paid_for"),
    and: t("and"),
    third_party_ballot: t("third_party_ballot"),
  }; 
  function constructGeographyIDs(cd, sldl, sldu, county) {
    const ids = [];

    if (cd) ids.push(`us_congress_${cd}`);
    if (sldl) ids.push(`state_house_${sldl}`);
    if (sldu) ids.push(`state_senate_${sldu}`);
    if (county) ids.push(`county_${county.toLowerCase()}`);

    return ids;
  }

  const getRaceData = async (payload, lang) => {
    const geographyIDs = constructGeographyIDs(
      payload.cd,
      payload.sldl,
      payload.sldu,
      payload.county
    );
    const countyDistrict = payload.countyDistrict || "";
    const schoolDistrict = payload.schoolDistrict || "";
    const municipality = payload.municipality || "";

    const racesQuery = `
      *[_type == 'racePage' && (
        is_statewide == true || 
        (race_type == "Commissioner" && geography_id in $geographyIDs && (county_district == "" || county_district == $countyDistrict)) ||
        (race_type == "District Attorney" && $county in counties) ||
        (race_type == "School Board" && geography_id in $geographyIDs && ($schoolDistrict == school_board_district || school_board_district == "") && !($municipality in municipality_exclusions)) ||
        (race_type != "Commissioner" && race_type != "District Attorney" && race_type != "School Board" && geography_id in $geographyIDs)
      )
      ]
      | {
        _id,
        race_name,
        race_name_display,
        geography_id,
        is_statewide,
        is_federal,
        target_race,
        priority,
        county_district,
        school_board_district,
        municipality_exclusions,
        counties,
        slug,
        "candidates": candidates[]-> | order(recommended desc),
        "order": select(
          race_type == "President" => 1,
          race_type == "U.S. House" => 2,
          race_type == "State Senate" => 3,
          race_type == "State House" => 4,
          race_type == "Commissioner" => 5,
          race_type == "District Attorney" => 6,
          race_type == "School Board" => 7,
          race_type == "Solicitor General" => 8,
          race_type == "Sheriff" => 9
        )
      }
      | order(order asc, _createdAt desc, school_board_district desc, county_district desc)
    `;

  
    try {
      const data = await client.fetch(racesQuery, {
        geographyIDs,
        countyDistrict,
        schoolDistrict,
        municipality,
        county: payload.county,
      });
  
      if (!data.length) {
        setNoRace(true);
        resetGlobalState();
        return data;
      } else {
        setNoRace(false);
      }
  
      if (lang === "kr") lang = "ko";
      const localizedRaces = data.map(race => {
        const localizedRaceName = race.race_name_display.find(item => item._key === lang);
        return {
          ...race,
          race_name_display: localizedRaceName ? localizedRaceName.value : race.race_name_display[0].value,
        };
      });
      return localizedRaces;
    } catch (error) {
      console.error("Error fetching race data from Sanity:", error);
      return { lookupError: "Error fetching race data." };
    }
  };  

  const fetchData = async () => {
    let noParams = !cd && !sldl && !sldu && !county;
    let payload = {
        cd: cd,
        sldl: sldl,
        sldu: sldu,
        county: county,
        countyDistrict: countyDistrict,
        schoolDistrict: schoolDistrict,
        municipality: municipality,
    }
    if (!noParams) try {
      const racesData = await getRaceData(payload, lang);
      if (racesData.lookupError) {
        console.error(racesData.lookupError);
      } else {
        addItemToList(racesData);
        setRaces(racesData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    // if (state.query && state.query !==location.pathname) {
    //     history.push(state.query);
    // }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  let candidateSelections =
    query.get("selections") && decodeURI(query.get("selections"));
  // let linkRedirect = useLocation().pathname + "?pdf=view";
  let imageUrl = [
    window.location.protocol,
    "//",
    window.location.host,
    config.seo.default.image,
  ].join("");
  let ogUrl = window.location.href;
  return query.get("pdf") === "view" ? (
    <Device>
      {({ isMobile }) => {
        if (isMobile) {
          return (
            <PDFDownloadLink
              document={
                <MyPDF2
                  ballots={races}
                  amendments={amendments}
                  selections={candidateSelections}
                  copy={pdfTranslations}
                  lang={i18n.language}
                />
              }
              fileName="ga_personalized_ballot.pdf"
            >
              {({ loading }) =>
                loading ? (
                  "Loading..."
                ) : (
                  <span className="download-pdf">Download PDF</span>
                )
              }
            </PDFDownloadLink>
          );
        }
        return (
          <div>
            {races && races.length > 0 ? (
              <PDFViewer
                width="100%"
                height="100%"
                style={{ position: "absolute", top: "0", left: "0" }}
              >
                <MyPDF2
                  ballots={races}
                  amendments={amendments}
                  selections={candidateSelections}
                  copy={pdfTranslations}
                  lang={i18n.language}
                />
              </PDFViewer>
            ) : null}
          </div>
        );
      }}
    </Device>
  ) : (
    <div>
      <Helmet>
        <title>{t("seo_default_home_title")}</title>
        <meta
          name="description"
          content={t("seo_default_home_page_description")}
        />
        <meta name="image" content={imageUrl} />
        <meta name="twitter:title" content={t("seo_home_tw_title")} />
        <meta
          name="twitter:description"
          content={t("seo_home_tw_description")}
        />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta itemProp="name" content={t("seo_default_home_title")} />
        <meta
          itemProp="description"
          content={t("seo_default_home_page_description")}
        />
        <meta itemProp="image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={t("seo_default_home_page_title")} />
        <meta
          property="og:description"
          content={t("seo_default_home_page_description")}
        />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
      <div className="home-top-email">
        <AlertSignup />
      </div>
      <Header />
      <div id="page-wrap">
        <LineSplit />
        <VoterGuide />
        {noRace ? (
          <div className="no-races">
            <p className="mobile-text-center">
              <Trans i18nKey="no_race">
                <a
                  href="https://mvp.sos.ga.gov/s/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
              </Trans>
            </p>
          </div>
        ) : (
          ""
        )}
        <LineSplit />
        {/* {state.step===1 ? <p className='sample-text' id="ballot_display">This is a <u>sample</u> voter guide, for your personalized guide please enter your address above.</p>: null} */}
        {/* <Candidate /> */}
        <Ballot ballots={races} />
        <div className="additional-resources">
          <h4 className="mobile-text-center">
            <span className="bg-emphasis">{t("additional_resources")}</span>
          </h4>
          <br />
          <p className="mobile-text-center">
            <Trans i18nKey="additional_office_descriptions">
              <Link to="/office-descriptions"> </Link>
            </Trans>
          </p>
          <p className="mobile-text-center">
            <Trans i18nKey="defer_to_your_ballot">
              <a
                href="https://mvp.sos.ga.gov/s/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
              </a>
            </Trans>
          </p>
          <Container fluid>
            <Row style={{ marginBottom: "40px" }}>
              <Col xs={12} md={12} lg={6} xl={8} className="form-col">
                <p className="mobile-text-center">
                  <Trans i18nKey="are_you_a_featured">
                    <a
                      href="mailto:candidates@thegeorgiavoterguide.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                    </a>
                  </Trans>
                </p>
              </Col>
              <Col
                xs={12}
                lg={6}
                xl={4}
                className="form-col"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <a
                  href="mailto:candidates@thegeorgiavoterguide.com"
                  target="_blank"
                  className="questionnaire-submit"
                >
                  {t("candidate_questionnaire")}
                  <Image src={iconHandRightWhite} alt="" />
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <LineSplit />
        <Footer flat={true} />
        <GoalMeter />
      </div>
    </div>
  );
}

export default BallotPage;
