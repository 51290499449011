import React, { useContext } from "react";
import { Image, Row, Col } from "react-bootstrap";
import "./header.styles.scss";
import { closeIcon } from "../../lib/images";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";

function HeaderNavBar({ toggleMenu }) {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -50;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  const { t } = useTranslation();

  const { resetGlobalState } = useContext(GlobalContext);

  let history = useHistory();
  const resetBallot = () => {
    resetGlobalState();
    history.push("/");
  };

  return (
    <div className="ctn-header-nav">
      <Row>
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 3, order: 1 }}
          className="col-header"
        >
          <Link to="/register-to-vote" onClick={() => toggleMenu()}>
            <div className="title">{t("register_to_vote")}</div>
          </Link>
          <HashLink
            smooth
            scroll={(el) => scrollWithOffset(el)}
            to={"/#voter-status"}
            className="sub-content"
            onClick={() => toggleMenu()}
          >
            {t("check_your_registration_status")}
          </HashLink>
          <Link
            to="/register-to-vote"
            className="sub-content"
            onClick={() => toggleMenu()}
          >
            {t("first_time_voter")}
          </Link>
          <Link
            to="/returning-register-to-vote"
            className="sub-content"
            onClick={() => toggleMenu()}
          >
            {t("returning_voter")}
          </Link>
        </Col>
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 3, order: 2 }}
          className="col-header"
        >
          <HashLink
            smooth
            scroll={(el) => scrollWithOffset(el)}
            to={"/learn-more#calendar"}
            onClick={() => toggleMenu()}
          >
            <div className="title">{t("voting_information")}</div>
          </HashLink>
          <Link to="/vote-by-mail" className="sub-content">
            {t("voting_by_mail")}
          </Link>
          <Link to="/vote-in-person-early" className="sub-content">
            {t("voting_early_in_person")}
          </Link>
          <Link to="/vote-in-person" className="sub-content">
            {t("voting_on_election_day")}
          </Link>
          <Link
            to="/voting-with-a-record"
            className="sub-content"
            onClick={() => toggleMenu()}
          >
            {t("voting_with_a_record")}
          </Link>
          <HashLink
            smooth
            scroll={(el) => scrollWithOffset(el)}
            to={"/learn-more#calendar"}
            className="sub-content"
            onClick={() => toggleMenu()}
          >
            {t("voting_deadlines")}
          </HashLink>
          <Link
            to="/learn-more"
            className="sub-content"
            onClick={() => toggleMenu()}
          >
            {t("voting_facts_myths")}
          </Link>
        </Col>
        <Col
          xs={{ span: 12, order: 3 }}
          md={{ span: 3, order: 3 }}
          className="col-header"
        >
          <HashLink
            onClick={() => resetBallot()}
            smooth
            scroll={(el) => scrollWithOffset(el)}
            to={"/#personalize"}
          >
            <div className="title">{t("ballot_guide")}</div>
          </HashLink>
          <HashLink
            onClick={() => resetBallot()}
            smooth
            scroll={(el) => scrollWithOffset(el)}
            to={"/#personalize"}
            className="sub-content"
          >
            {t("create_your_personalized")}
          </HashLink>
          <Link to="/office-descriptions" className="sub-content">
            {t("office_descriptions")}
          </Link>
        </Col>
        <Col
          xs={{ span: 12, order: 3 }}
          md={{ span: 3, order: 3 }}
          className="col-header"
        >
          <Link to="/about-us" onClick={() => toggleMenu()}>
            <div className="title">{t("about_us")}</div>
          </Link>
          <HashLink
            smooth
            scroll={(el) => scrollWithOffset(el)}
            to={"/about-us#partners"}
            className="sub-content"
            onClick={() => toggleMenu()}
          >
            {t("partner_organizations")}
          </HashLink>
          <HashLink
            smooth
            scroll={(el) => scrollWithOffset(el)}
            to={"/about-us#contact"}
            className="sub-content"
            onClick={() => toggleMenu()}
          >
            {t("contact_us")}
          </HashLink>
        </Col>
        <Image
          src={closeIcon}
          className="btn-close clickable"
          alt=""
          onClick={() => toggleMenu()}
        />
      </Row>
    </div>
  );
}
export default HeaderNavBar;
