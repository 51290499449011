import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import "./footer.styles.scss";
// import config from "../../lib/config";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

function Footer(props) {
  // let shareUrl = window.location.origin;
  // let shareDescription = config.seo.default.share_description;
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -70;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  const { t } = useTranslation();
  return (
    <div className="footer-container">
      {/* {props.flat ? null : <div className="slantedDiv" />} */}
      <Container className="footer-top" fluid>
        <Row noGutters className="helpful-links">
          <Col xs="6" md="4">
            <Link to="/register-to-vote">
              <h4>{t("register_to_vote")}</h4>
            </Link>
            <p>
              <HashLink
                smooth
                scroll={(el) => scrollWithOffset(el)}
                to={"/#voter-status"}
              >
                {t("check_your_registration_status")}
              </HashLink>
            </p>
            <p>
              <Link to="/register-to-vote">{t("first_time_voter")}</Link>
            </p>
            <p>
              <Link to="/returning-register-to-vote">
                {t("returning_voter")}
              </Link>
            </p>
          </Col>
          <Col xs="6" md="4">
            <HashLink
              smooth
              scroll={(el) => scrollWithOffset(el)}
              to={"/learn-more#calendar"}
            >
              <h4>{t("voting_information")}</h4>
            </HashLink>
            <p>
              <Link to="/vote-by-mail">{t("voting_by_mail")}</Link>
            </p>
            <p>
              <Link to="/vote-in-person-early">
                {t("voting_early_in_person")}
              </Link>
            </p>
            <p>
              <Link to="/vote-in-person">{t("voting_on_election_day")}</Link>
            </p>
            <p>
              <Link to="/voting-with-a-record">
                {t("voting_with_a_record")}
              </Link>
            </p>
            <p>
              <HashLink
                smooth
                scroll={(el) => scrollWithOffset(el)}
                to={"/learn-more#calendar"}
              >
                {t("voting_deadlines")}
              </HashLink>
            </p>
            <p>
              <Link to="/learn-more">{t("voting_facts_myths")}</Link>
            </p>
            <p>
              <Link to="/office-descriptions">{t("office_descriptions")}</Link>
            </p>
          </Col>
          <Col xs="6" md="4" className="about-footer">
            <Link to="/about-us">
              <h4>{t("about_us")}</h4>
            </Link>
            <p>
              <HashLink
                smooth
                scroll={(el) => scrollWithOffset(el)}
                to={"/about-us#partners"}
              >
                {t("partner_organizations")}
              </HashLink>
            </p>
            <p>
              <HashLink
                smooth
                scroll={(el) => scrollWithOffset(el)}
                to={"/about-us#contact"}
              >
                {t("contact_us")}
              </HashLink>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="footer-bottom">
        <span>Paid for by Dialogue Collaborative</span>
        <span className="footer-bottom-spacer">|</span>
        <span>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </span>
      </div>
    </div>
  );
}

export default Footer;
