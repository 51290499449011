import React, { useEffect } from "react";
import { Image, Collapse, Container } from "react-bootstrap";
import "./QuizLandingPage.style.scss";
import {
  starIconOrange,
  iconPlus,
  iconMinus,
  iconWarning,
  iconHandOrange,
  iconQuizLP01,
  iconQuizLP02,
  iconQuizLP03,
  iconQuizLP04,
  iconQuizLP05,
} from "../../lib/images";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Trans, useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

function QuizLPVoteInPerson1() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  let hashParam =
    window.location.hash && window.location.hash.replace("#", "").toLowerCase();

  const [open1, setOpen1] = useState(!isMobile);
  const [open2, setOpen2] = useState(!isMobile);
  const [open3, setOpen3] = useState(!isMobile);
  const [open4, setOpen4] = useState(!isMobile);
  const [open5, setOpen5] = useState(!isMobile);

  const [sec2, setSec2] = useState(!isMobile);
  const [sec3, setSec3] = useState(false);
  const [sec4, setSec4] = useState(false);
  const [sec5, setSec5] = useState(false);
  const [sec6, setSec6] = useState(false);
  const [sec9, setSec9] = useState(false);
  const [sec10, setSec10] = useState(false);
  const [sec11, setSec11] = useState(false);
  const [sec12, setSec12] = useState(false);

  const { t } = useTranslation();
  const toggleSec = (sec) => {
    setSec2(false);
    setSec3(false);
    setSec4(false);
    setSec5(false);
    setSec6(false);
    setSec9(false);
    setSec10(false);
    setSec11(false);
    setSec12(false);

    if (sec === "sec2") setSec2(true);
    if (sec === "sec3") setSec3(true);
    if (sec === "sec4") setSec4(true);
    if (sec === "sec5") setSec5(true);
    if (sec === "sec6") setSec6(true);
    if (sec === "sec9") setSec9(true);
    if (sec === "sec10") setSec10(true);
    if (sec === "sec11") setSec11(true);
    if (sec === "sec12") setSec12(true);
  };
  useEffect(() => {
    if (hashParam === "whatif") {
      setOpen5("true");
      goToAnchor("whatif");
    }
  }, [hashParam]);
  function goToAnchor(anchor) {
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#" + anchor;
    return false;
  }
  let desktopFAQs = (
    <div id="collapse-text-05" className="collapse-text-content">
      <div className="collape-item-leader">{t("sometimes_things_may")}</div>
      <div className="collapse-text-item">
        <div className="col-left">
          <div className="text-content">
            <button
              type="button"
              className={sec2 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec2")}
            >
              {t("you_need_assistance")}
            </button>
            <button
              type="button"
              className={sec3 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec3")}
            >
              {t("you_are_over_75")}
            </button>
            <HashLink
              to="#whatif"
              type="button"
              className={sec4 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec4")}
            >
              {t("you_at_the_wrong")}
            </HashLink>
            <HashLink
              to="#whatif"
              type="button"
              className={sec5 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec5")}
            >
              {t("you_end_up")}
            </HashLink>
            <HashLink
              to="#whatif"
              type="button"
              className={sec6 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec6")}
            >
              {t("you_identify_as_transgender")}
            </HashLink>
            <HashLink
              to="#whatif"
              type="button"
              className={sec9 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec9")}
            >
              {t("you_requested_vote_by_mail_ballot")}
            </HashLink>
            <HashLink
              to="#whatif"
              type="button"
              className={sec10 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec10")}
            >
              {t("vote_by_mail_ballot_never_arrived")}
            </HashLink>
            <HashLink
              to="#whatif"
              type="button"
              className={sec11 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec11")}
            >
              {t("you_changed_addresses")}
            </HashLink>
            <HashLink
              to="#whatif"
              type="button"
              className={sec12 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec12")}
            >
              {t("you_need_to_bring_your_kids")}
            </HashLink>
          </div>
        </div>
        <div className="col-right">
          {sec2 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="title">
                  <span className="bg-emphasis">
                    {t("you_can_bring_someone")}
                  </span>
                </div>
                <div className="content">
                  <Trans i18nKey="this_is_what">
                    <ul>
                      <li></li>
                      <li></li>
                    </ul>
                  </Trans>
                </div>
              </div>
              <div className="col-right-image">
                <Image src={iconQuizLP05} />
              </div>
            </div>
          ) : null}
          {sec3 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="title">
                  <span className="bg-emphasis">
                    {t("if_you_are_75_years")}
                  </span>
                </div>
                <div className="content">{t("if_you_have_a_disability")}</div>
                <div className="col-right-image">
                  <div className="video-embed-container">
                    <iframe
                      className="accessibility-guide-video"
                      src="https://www.youtube.com/embed/VYePIwvl1EY?autoplay=0&rel=0"
                      frameBorder="0"
                      allow="encrypted-media"
                      allowFullScreen
                      title="Voting Accessibility Guide"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {sec4 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="title">
                  <span className="bg-emphasis">{t("if_it_before_5pm")}</span>
                </div>
                <div className="content">
                  <Trans i18nKey="if_its_past_5pm"></Trans>
                </div>
              </div>
            </div>
          ) : null}
          {sec5 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="content">
                  <p>
                    <Trans i18nKey="if_you_voted_using">
                      <a
                        href="https://mvp.sos.ga.gov/s/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                      </a>
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {sec6 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="content">
                  <Trans i18nKey="georgia_equality_has_compiled">
                    <a
                      href="https://s11863.pcdn.co/wp-content/uploads/2020/10/transvote1-guide.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                    </a>
                  </Trans>
                </div>
              </div>
            </div>
          ) : null}
          {sec9 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="content">
                  <Trans i18nKey="you_can_still_vote_in_person"></Trans>
                </div>
              </div>
            </div>
          ) : null}
          {sec10 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="content">
                  <Trans i18nKey="vbm_never_arrived_options">
                    <a
                      href="https://sos.ga.gov/county-elections-offices"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </Trans>
                </div>
              </div>
            </div>
          ) : null}
          {sec11 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="content">
                  <Trans i18nKey="changed_addresses_didnt_notify">
                    <a
                      href="https://mvp.sos.ga.gov/s/"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </Trans>
                </div>
              </div>
            </div>
          ) : null}
          {sec12 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="content">
                  <Trans i18nKey="bring_your_kids"></Trans>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
  let mobileFAQs = (
    <div id="collapse-text-05">
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("you_need_assistance")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec2(!sec2)}
            aria-controls="collapse-faq-02"
            aria-expanded={sec2}
          >
            {sec2 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec2} className="col-right">
          <Container id="collapse-faq-02" className="section-item">
            <div>
              <div className="title">
                <span className="bg-emphasis">
                  {t("you_can_bring_someone")}
                </span>
              </div>
              <div className="content">
                <Trans i18nKey="this_is_what">
                  <ul>
                    <li></li>
                    <li></li>
                  </ul>
                </Trans>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("you_are_over_75")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec3(!sec3)}
            aria-controls="collapse-faq-03"
            aria-expanded={sec3}
          >
            {sec3 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec3} className="col-right">
          <Container id="collapse-faq-03" className="section-item">
            <div style={{ paddingBottom: "10px" }}>
              <div className="title">
                <span className="bg-emphasis">{t("if_you_are_75_years")}</span>
              </div>
              <div className="content">{t("if_you_have_a_disability")}</div>
              <div className="col-right-image">
                <div className="video-embed-container">
                  <iframe
                    className="accessibility-guide-video"
                    src="https://www.youtube.com/embed/VYePIwvl1EY?autoplay=0&rel=0"
                    frameBorder="0"
                    allow="encrypted-media"
                    allowFullScreen
                    title="Voting Accessibility Guide"
                  />
                </div>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("you_at_the_wrong")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec4(!sec4)}
            aria-controls="collapse-faq-04"
            aria-expanded={sec4}
          >
            {sec4 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec4} className="col-right">
          <Container id="collapse-faq-04" className="section-item">
            <div>
              <div>
                <div className="title">
                  <span className="bg-emphasis">{t("if_it_before_5pm")}</span>
                </div>
                <div className="content">
                  <Trans i18nKey="if_its_past_5pm"></Trans>
                </div>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("you_end_up")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec5(!sec5)}
            aria-controls="collapse-faq-05"
            aria-expanded={sec5}
          >
            {sec5 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec5} className="col-right">
          <Container id="collapse-faq-05" className="section-item">
            <div>
              <div className="content">
                <p>
                  <Trans i18nKey="if_you_voted_using">
                    <a
                      href="https://mvp.sos.ga.gov/s/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                    </a>
                  </Trans>
                </p>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">
            {t("you_identify_as_transgender")}
          </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec6(!sec6)}
            aria-controls="collapse-faq-06"
            aria-expanded={sec6}
          >
            {sec6 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec6} className="col-right">
          <Container id="collapse-faq-06" className="section-item">
            <div>
              <div className="content">
                <Trans i18nKey="georgia_equality_has_compiled">
                  <a
                    href="https://s11863.pcdn.co/wp-content/uploads/2020/10/transvote1-guide.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                  </a>
                </Trans>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">
            {t("you_requested_vote_by_mail_ballot")}
          </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec9(!sec9)}
            aria-controls="collapse-faq-09"
            aria-expanded={sec9}
          >
            {sec9 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec9} className="col-right">
          <Container id="collapse-faq-09" className="section-item">
            <div>
              <div className="content">
                <Trans i18nKey="you_can_still_vote_in_person"></Trans>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">
            {t("vote_by_mail_ballot_never_arrived")}
          </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec10(!sec10)}
            aria-controls="collapse-faq-10"
            aria-expanded={sec10}
          >
            {sec10 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec10} className="col-right">
          <Container id="collapse-faq-10" className="section-item">
            <div>
              <div className="content">
                <Trans i18nKey="vbm_never_arrived_options">
                  <a
                    href="https://sos.ga.gov/county-elections-offices"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </Trans>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("you_changed_addresses")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec11(!sec11)}
            aria-controls="collapse-faq-11"
            aria-expanded={sec11}
          >
            {sec11 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec11} className="col-right">
          <Container id="collapse-faq-11" className="section-item">
            <div>
              <div className="content">
                <Trans i18nKey="changed_addresses_didnt_notify">
                  <a
                    href="https://mvp.sos.ga.gov/s/"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </Trans>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">
            {t("you_need_to_bring_your_kids")}
          </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec12(!sec12)}
            aria-controls="collapse-faq-12"
            aria-expanded={sec12}
          >
            {sec12 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec12} className="col-right">
          <Container id="collapse-faq-12" className="section-item">
            <div>
              <div className="content">
                <Trans i18nKey="bring_your_kids"></Trans>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
    </div>
  );
  return (
    <div className="ctn-quiz-lp04">
      <div className="quiz-lp-top-header">
        <Image src={starIconOrange} className="icon-star-left" alt="" />
        <div className="top-header-title">
          <p>{t("georgians_made_history_during")}</p>
        </div>
        <Image src={starIconOrange} className="icon-star-right" alt="" />
      </div>
      <div className="quiz-lp-btn-action">
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen1("true");
            goToAnchor("when");
            return false;
          }}
        >
          {t("when")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen2("true");
            goToAnchor("where");
            return false;
          }}
        >
          {t("where")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen3("true");
            goToAnchor("why");
            return false;
          }}
        >
          {t("why")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen4("true");
            goToAnchor("how");
            return false;
          }}
        >
          {t("how")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen5("true");
            goToAnchor("whatif");
            return false;
          }}
        >
          {t("what_if")}
        </button>
      </div>
      <div className="collape-item" id="when">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("when")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen1(!open1)}
            aria-controls="collapse-text-01"
            aria-expanded={open1}
          >
            {open1 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open1}>
          <div id="collapse-text-01" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  <span className="bg-emphasis election-day-title">
                    {t("election_day_is_november_5th")}
                  </span>
                </div>
                <div className="text-content">
                  <Trans i18nKey="most_polling_places_open_at_7_am">
                    <a
                      href="https://mvp.sos.ga.gov/s/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                    </a>
                  </Trans>
                </div>
                <div className="div-warning">
                  <Image src={iconWarning} className="icon-warning" />{" "}
                  <div className="warning-title">{t("important")}</div>
                  <p>
                    <strong>
                      <Trans i18nKey="if_you_get_in_line_before">
                        <em> </em>
                      </Trans>
                    </strong>
                  </p>
                </div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP01} />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="where">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("where")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen2(!open2)}
            aria-controls="collapse-text-01"
            aria-expanded={open2}
          >
            {open2 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open2}>
          <div id="collapse-text-02" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  <Trans i18nKey="if_you_voting_on_election_day">
                    <a
                      href="https://mvp.sos.ga.gov/s/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                    </a>
                  </Trans>
                </div>
                <a
                  href="https://mvp.sos.ga.gov/s/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button type="button" className="btn-action-quiz-lp">
                    {t("find_my_polling_place")}
                  </button>
                </a>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP02} />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="why">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("why")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen3(!open3)}
            aria-controls="collapse-text-03"
            aria-expanded={open3}
          >
            {open3 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open3}>
          <div id="collapse-text-03" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  <Trans i18nKey="georgia_voters_realize">
                    <em> </em>
                  </Trans>
                </div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP03} />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="how">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("how")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen4(!open4)}
            aria-controls="collapse-text-04"
            aria-expanded={open4}
          >
            {open4 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open4}>
          <div id="collapse-text-04" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">{t("if_youre_registered")}</div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("georgia_driver_license")}
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {/* Student ID from a <a href="https://sos.ga.gov/page/georgia-colleges-and-universities" target="_blank" rel="noopener noreferrer">Georgia public college or university</a> */}
                    <Trans i18nKey="student_id_from_a">
                      <a
                        href="https://sos.ga.gov/page/georgia-colleges-and-universities"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                      </a>
                    </Trans>
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("id_card_issued_by")}
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("free_voter_id")}
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("valid_passport")}
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("valid_employee_id")}
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("valid_military")}
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("valid_tribal_photo_id")}
                  </div>
                </div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP04} />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="whatif">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("what_if")}</div>

          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen5(!open5)}
            aria-controls="collapse-text-03"
            aria-expanded={open5}
          >
            {open5 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open5}>{isMobile ? mobileFAQs : desktopFAQs}</Collapse>
      </div>
    </div>
  );
}
export default QuizLPVoteInPerson1;
