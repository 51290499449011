import React, { useEffect } from "react";
import { Image, Collapse, Container, Row, Col } from "react-bootstrap";
import Dropdown from "react-dropdown";
import "../QuizLandingPage/QuizLandingPage.style.scss";
import {
  starIconOrange,
  iconPlus,
  iconMinus,
  iconWarning,
  iconDropdown,
} from "../../lib/images";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import county_info from "../../data/county_info.json";
import axios from "axios";
import config from "../../lib/config";
import parse from "html-react-parser";

function CountyInformation() {
  let countyParam =
    window.location.hash && window.location.hash.replace("#", "");
  const { t } = useTranslation();
  const [curentIndexCounty, setCurentIndexCounty] = useState(countyParam);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [currentCountyInfo, setCurrentCountyInfo] = useState(county_info);
  const [remoteFetched, setRemoteFetched] = useState(0);
  const countyInfoRemoteURL = config.apis.countyInfoRemoteURL;

  function goToAnchor(anchor) {
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#" + anchor;
    return false;
  }

  function onChangeCounty(index) {
    setCurentIndexCounty(-1);
    setCurentIndexCounty(index);
    goToAnchor(index);
  }

  function toggleCounty(index) {
    if (curentIndexCounty === index) {
      setCurentIndexCounty(-1);
      return;
    }
    setCurentIndexCounty(index);
  }
  useEffect(() => {
    setCurentIndexCounty(curentIndexCounty);
    goToAnchor(curentIndexCounty);
    if (!remoteFetched) {
      setRemoteFetched(1);
      axios
        .get(countyInfoRemoteURL)
        .then((response) => {
          if (response.data) {
            setCurrentCountyInfo(response.data);
          } else {
            setCurrentCountyInfo(county_info);
          }
        })
        .catch((error) => {
          setCurrentCountyInfo(county_info);
        });
    }
  }, [curentIndexCounty]);
  let tabsDisplayTemp = [];
  let dataDisplay = [];

  const countyKey = currentCountyInfo && Object.keys(currentCountyInfo);
  if (countyKey)
    countyKey.map((e, index) => {
      let county = e.toLowerCase().replace(" ", "");
      tabsDisplayTemp.push({ value: county, label: county.toUpperCase() });
      let model = currentCountyInfo[e];
      dataDisplay.push(model);
      return dataDisplay;
    });
  let tabsDisplay = tabsDisplayTemp;
  let countyList = dataDisplay;

  return (
    <div className="ctn-quiz-lp03 office-description">
      <div className="quiz-lp-top-header">
        <Image src={starIconOrange} className="icon-star-left" alt="" />
        <div className="top-header-title">
          <p>{t("runoff_early_voting")}</p>
        </div>
        <Image src={starIconOrange} className="icon-star-right" alt="" />
      </div>
      <div className="ballot-container">
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            margin: "10px auto",
            width: "100%",
          }}
        >
          {!isMobile ? (
            <span className="issue-tab-text">{t("select_your_county")}</span>
          ) : null}
          <Dropdown
            options={tabsDisplay}
            onChange={(e) => onChangeCounty(e.value)}
            className="issues-dropdown"
            arrowOpen={
              <Image
                src={iconDropdown}
                alt=""
                className="icon-drop-down open"
              />
            }
            arrowClosed={
              <Image src={iconDropdown} alt="" className="icon-drop-down" />
            }
            placeholder={isMobile ? t("select_your_county") : "Select..."}
          />
        </Row>
      </div>
      {countyList &&
        countyList.map((element, index) => {
          const regexLocation = /<address>(\d+?.+?)<\/address>/g;
          // let linkedLocation = element.location_time.replaceAll(regexLocation, "<a href='https://www.google.com/maps?q=$1' target='_blank'>$1</a>");
          let linkedDropbox = element.dropbox.replaceAll(
            regexLocation,
            "<a href='https://www.google.com/maps?q=$1' target='_blank'>$1</a>"
          );
          let linkedLocation = element.location_time.replaceAll(
            regexLocation,
            function (a, b) {
              return (
                "<a href='https://www.google.com/maps?q=" +
                encodeURI(b) +
                "' target='_blank'>" +
                b +
                "</a>"
              );
            }
          );

          let county = countyKey[index].toLowerCase().replace(" ", "");
          return (
            <div className="collape-item" key={index}>
              <div className="collape-item-top" id={county}>
                <div className="collape-item-lable">
                  {element.full_title.replace(" County", "").toUpperCase()}{" "}
                  COUNTY
                </div>
                <button
                  type="button"
                  className="collape-item-button"
                  onClick={() => toggleCounty(county)}
                  aria-controls={"collapse-text-" + index}
                  aria-expanded={index}
                >
                  {curentIndexCounty === county ? (
                    <Image src={iconMinus} className="icon-minus" />
                  ) : (
                    <Image src={iconPlus} />
                  )}
                </button>
              </div>
              <Collapse in={curentIndexCounty === county}>
                <div
                  id={"collapse-text-" + index}
                  className="collapse-text-content"
                >
                  <div className="collapse-text-item row">
                    <div className="col-12 col-md-7">
                      <h4>{t("runoff_early")}</h4>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {element.ev_dates}
                      </p>
                      {element.location_time ? (
                        <>
                          <h4>{t("runoff_polling")}</h4>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {parse(linkedLocation)}
                          </p>
                        </>
                      ) : null}
                      {element.dropbox ? (
                        <>
                          <h4>{t("runoff_dropbox")}</h4>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {parse(linkedDropbox)}
                          </p>
                        </>
                      ) : null}
                      <div className="d-block d-md-none">
                        <p>
                          <Trans i18nKey="county_map">
                            <a
                              href="https://www.google.com/maps/d/viewer?mid=1LUeqWjYQKBHUeI30C6_O6qLQtH4BsCw&ll=32.85782317788324%2C-83.25960994999998&z=7"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                            </a>
                          </Trans>
                        </p>
                        {element.important ? (
                          <div className="div-warning">
                            <Image src={iconWarning} className="icon-warning" />{" "}
                            <div className="warning-title">
                              {t("important")}
                            </div>
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              {element.important}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <button
                        type="button"
                        className="btn-back-to-top"
                        onClick={() => goToAnchor("top")}
                      >
                        {t("runoff_back_to_top")}
                      </button>
                    </div>
                    <div className="col-right col-12 col-md-5 d-none d-md-block">
                      <p>
                        <Trans i18nKey="county_map">
                          <a
                            href="https://www.google.com/maps/d/viewer?mid=1LUeqWjYQKBHUeI30C6_O6qLQtH4BsCw&ll=32.85782317788324%2C-83.25960994999998&z=7"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                          </a>
                        </Trans>
                      </p>
                      {element.important ? (
                        <div className="div-warning">
                          <Image src={iconWarning} className="icon-warning" />{" "}
                          <div className="warning-title">{t("important")}</div>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {element.important}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          );
        })}
    </div>
  );
}
export default CountyInformation;
