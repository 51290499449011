import {
    metaDefaultImage,
    metaDefaultQuiz,
    metaSoloHD34,
    metaSoloHD35,
    metaSoloHD43,
    metaSoloHD44,
    metaSoloHD45,
    metaSoloHD49,
    metaSoloHD52,
    metaSoloHD73,
    metaSoloHD109,
    metaSoloHD110,
    metaSoloHD117,
    metaSoloHD119,
    metaSoloHD145,
    metaSoloHD147,
    metaSoloHD151,
    metaSoloHD164
} from "./images";

const config = {
  apis: {
    baseDivio: "https://voterguide-stage.us.aldryn.io/en/api/v1", // API Server
    base: "https://api-2022-a.thegeorgiavoterguide.com/api/", // API Server,
    baseDev: "https://api-2022-a.thegeorgiavoterguide.com/api/", // API Server
    countyInfoRemoteURL:
      "https://media-2022-a.thegeorgiavoterguide.com/documents/county_info.json", // quick and dirty reference for CMS-hosted county info
    baseEB: "http://voterguidecms-dev.us-east-1.elasticbeanstalk.com/api/v1", // API Server
    baseImage: "https://api.thegeorgiavoterguide.com", // API Image base
    targetSmartAPI:
      "https://ts-proxy-district.thegeorgiavoterguide.workers.dev/?address=", //Target Smart API
    districtAPI:
      "https://georgia-voter-guide.vercel.app/api/addressLookup?address=",
    ballotAPI:
      "https://georgia-voter-guide.vercel.app/api/ballotLookup?address=",
    crmurl: "https://ts-proxy.thegeorgiavoterguide.workers.dev", // CloudFlare worker - proxy to TS RegCheck API
    localHost: "http://localhost:8000/api/v1", // API localhost for dev
    pages: "v1/pages/", // Endpoint for serialized pages
    races: "v1/racepage/", // Endpoint for serialized races,
    amendments: "v1/measurepage/", //Endpoint for amendments
    googleCivics:
      "https://www.googleapis.com/civicinfo/v2/representatives?key=AIzaSyBjy8iKXECXsZ7ZxkSBOqRUORShodFWdps&address={address}&includeOffices=false", // Google Civics API
    actionKit: "https://act.progressga.org/rest/v1/action/", // Actionkit API,
    ballotFilters: "(race_name=United States Senate)", // Filters for ballot
    raceFilters: "(race_name={race})", // Filters for race
  },
  featureRecommendation: false,
  // gaTrackingId: "UA-175198626-1",
  gaTrackingId: "",
  CONST_GEORGIA_OCDID: "ocd-division/country:us/state:ga",
  actionKitPage: "ga-guide-signup",
  actionKitPageHeader: "ga-guide-signup-header",
  actionKitBallotPage_en: "gavg_pb_send",
  actionKitBallotPage_es: "gavg_pb_send_es",
  actionKitBallotPage_kr: "gavg_pb_send_kr",
  akSourceName: "source",
  issuesTabs: {
    healthcare: "Healthcare",
    reproductive_rights: "Reproductive Rights",
    covid: "COVID-19",
    democracy: "Protecting Democracy",
    criminal: "Criminal Justice",
    national_defense: "National Defense",
    economy: "Economy and Jobs",
    education: "Education",
    immigration: "Immigration",
    gun_control: "Gun Control",
    environment: "Environment",
    transportation: "Transportation and Infrastructure",
    lgbtqia: "LGBTQIA+",
    marijuana: "Marijuana",
    religion: "Religion",
  },
  votingHistoryTabs: {
    vht_healthcare: "Healthcare",
    vht_reproductive_rights: "Reproductive Rights",
    vht_covid: "COVID-19",
    vht_democracy: "Protecting Democracy",
    vht_criminal: "Criminal Justice",
    vht_national_defense: "National Defense",
    vht_economy: "Economy and Jobs",
    vht_education: "Education",
    vht_immigration: "Immigration",
    vht_gun_control: "Gun Control",
    vht_environment: "Environment",
    vht_enviornment: "Enviornment", // TODO Fix spelling error in data source
    vht_transportation: "Transportation and Infrastructure",
    vht_lgbtqia: "LGBTQIA+",
    vht_marijuana: "Marijuana",
    vht_religion: "Religion", // TODO not in allowed Issues, show it?
  },
  seo: {
    // default: {
    //     share_title: "We're gearing up for the 2022 election!",
    //     share_description: "The 2022 Georgia elections are approaching! Find out everything you need to know so you're ready when the ballots drop.",
    //     image: metaDefaultImage,
    //     title: "The Georgia Voter Guide",
    //     description: "Get all of the information you need to cast your vote in the 2022 Georgia elections."
    // },
    default: {
      title: "We're Your Guide to the 2023 Election - The Georgia Voter Guide",
      tw_title: "Live in Georgia? Take our voting quiz",
      tw_description:
        "Voting for Georgians, made easy. Take our voting quiz to kickstart your voting journey and make your voice heard.",
      image: metaDefaultImage,
      page_title:
        "We're Your Guide to the 2023 Election | The Georgia Voter Guide",
      page_description:
        "A run-down of exactly what you need to know to vote by mail, early or on Election Day. Take our election quiz to get started.",
    },
    home: {
      title: "We're Your Guide to the 2022 Election - The Georgia Voter Guide",
      tw_title: "Live in Georgia? Take our voting quiz",
      tw_description:
        "Voting for Georgians, made easy. Take our voting quiz to kickstart your voting journey.",
      image: metaDefaultQuiz,
      page_title:
        "We're Your Guide to the 2022 Election | The Georgia Voter Guide",
      page_description:
        "A run-down of exactly what you need to know to vote by mail, early or on Election Day. Take our election quiz to get started.",
    },
    privacy: {
      title: "Privacy Policy - The Georgia Voter Guide",
      tw_title: "Live in Georgia? Take our voting quiz",
      tw_description:
        "Voting for Georgians, made easy. Take our voting quiz to kickstart your voting journey.",
      image: metaDefaultQuiz,
      page_title: "Privacy Policy | The Georgia Voter Guide",
      page_description:
        "A run-down of exactly what you need to know to vote by mail, early or on Election Day. Take our election quiz to get started.",
    },
    aboutUs: {
      title: "About Us - The Georgia Voter Guide",
      tw_title: "We're making voting information accessible",
      tw_description:
        "The Georgia Voter Guide is brought to you by a coalition of orgs who believe in civic empowerment.",
      image: metaDefaultImage,
      page_title: "About Us | The Georgia Voter Guide",
      page_description:
        "Find out more about our partner orgs, and how to make a donation to help ensure accurate voting information reaches all Georgians.",
    },
    registerToVote: {
      title:
        "Voter Registration for First Time Voters - The Georgia Voter Guide",
      tw_title: "Registering to vote for the first time in Georgia?",
      tw_description:
        "Here's how you can get registered to vote in time for the 2022 November election",
      image: metaDefaultImage,
      page_title:
        "Voter Registration for First Time Georgia Voters | The Georgia Voter Guide",
      page_description:
        "Find out more about our partner orgs, and how to make a donation to help ensure accurate voting information reaches all Georgians.",
    },
    returningRegister: {
      title:
        "Voter Registration for Returning Voters - The Georgia Voter Guide",
      tw_title: "Need voter registration help in Georgia? Look no further.",
      tw_description:
        "Here's how you can get registered to vote in time for the 2022 November election.",
      image: metaDefaultImage,
      page_title:
        "Voter Registration for Returning Voters | The Georgia Voter Guide",
      page_description:
        "Need to re-register to vote? We've got you covered. Learn more about how to register to vote online, in-person, or by mail.",
    },
    voteByMail: {
      title: "Vote by Mail Voting Resources - The Georgia Voter Guide",
      tw_title: "Voting by mail in Georgia?",
      tw_description:
        "Learn exactly what you need to know to successfully cast an absentee ballot this election.",
      image: metaDefaultImage,
      page_title: "Absentee Voting Resources | The Georgia Voter Guide",
      page_description:
        "Learn more about how vote to by mail this election, including deadlines, how to find a dropbox, and the last day you can request a ballot.",
    },
    votePerson: {
      title: "Election Day Voting Resources - The Georgia Voter Guide ",
      tw_title: "Voting on Election Day in Georgia?",
      tw_description:
        "We have the breakdown of everything you need to know if you plan on voting in-person on November 8th.",
      image: metaDefaultImage,
      page_title: "Election Day Voting Resources | The Georgia Voter Guide",
      page_description:
        "Election Day is closer than you think. Find out what you need to know to cast your vote this November 8th.",
    },
    votePersonEarly: {
      title: "Early Vote Resources - The Georgia Voter Guide",
      tw_title: "Voting early in Georgia?",
      tw_description:
        "We have the breakdown of everything you need to know if you plan on voting early this election.",
      image: metaDefaultImage,
      page_title: "Early Vote Resources | The Georgia Voter Guide ",
      page_description:
        "Learn more about how to vote early in Georgia, including important dates, what to bring, and what to do if you need assistance.",
    },
    voteWithRecord: {
      title: "Voting with a Record - The Georgia Voter Guide",
      tw_title: "Voting with a Record in Georgia?",
      tw_description:
        "If you have a record, or are currently serving a sentence, you may still have the right to cast a vote.",
      image: metaDefaultImage,
      page_title: "Voting with a record | The Georgia Voter Guide ",
      page_description:
        "Learn more about how to vote with a record, including important dates, what to bring, and what to do if you need assistance.",
    },
    learnMore: {
      title: "Test Your Voting Knowledge - The Georgia Voter Guide ",
      tw_title: "How much do you know about voting in Georgia?",
      tw_description:
        "Take our quiz and test your knowledge and see if you can tell if these statements are fact or myth.",
      image: metaDefaultImage,
      page_title: "Voting Facts and Myths | The Georgia Voter Guide ",
      page_description:
        "Test your knowledge and learn about the different ways you can cast a vote in Georgia this election.",
    },
    guide: {
      share_title: "We're gearing up for the 2022 election!",
      share_description:
        "The 2022 Georgia elections are approaching! Find out everything you need to know so you're ready when the ballots drop.",
      image: metaDefaultImage,
      title: "The Georgia Voter Guide",
      description:
        "Essential information about the 2022 Georgia elections. Learn more about the candidates and the issues that matter to you.",
    },
    soloTemplate: {
      share_title:
        "I just learned about the candidates running for State House District {sldl}.",
      share_description:
        "Learn more about the candidates, their stances on the issues that count, and why this race matters.",
      title: "The Georgia Voter Guide | Georgia's State House District {sldl}",
    },
    soloStateHouse: {
      34: {
        image: metaSoloHD34,
        description:
          "Learn more about Bert Reeves and Priscilla Smith, the two candidates running for State House District 34.",
      },
      35: {
        image: metaSoloHD35,
        description:
          "Learn more about Ed Setzler and Kyle Rinaudo, the two candidates running for State House District 35.",
      },
      43: {
        image: metaSoloHD43,
        description:
          "Learn more about Sharon Cooper and Luisa Wakeman, the two candidates running for State House District 43.",
      },
      44: {
        image: metaSoloHD44,
        description:
          "Learn more about Don Parsons and Connie Di Cicco, the two candidates running for State House District 44.",
      },
      45: {
        image: metaSoloHD45,
        description:
          "Learn more about Sara Tindall Ghazal and Matt Dollar, the two candidates running for State House District 45.",
      },
      49: {
        image: metaSoloHD49,
        description:
          "Learn more about Jason Hayes and Chuck Martin, the two candidates running for State House District 49.",
      },
      52: {
        image: metaSoloHD52,
        description:
          "Learn more about Shea Roberts and Deborah Silcox, the two candidates running for State House District 52.",
      },
      73: {
        image: metaSoloHD73,
        description:
          "Learn more about Karen Mathiak and William Harris, the two candidates running for State House District 73.",
      },
      109: {
        image: metaSoloHD109,
        description:
          "Learn more about Dale Rutledge and Regina Lewis-Ward, the two candidates running for State House District 109.",
      },
      110: {
        image: metaSoloHD110,
        description:
          "Learn more about Clint Crowe and Ebony Carter, the two candidates running for State House District 110.",
      },
      117: {
        image: metaSoloHD117,
        description:
          "Learn more about Houston Gaines and Mokah Jasmine Johnson, the two candidates running for State House District 117.",
      },
      119: {
        image: metaSoloHD119,
        description:
          "Learn more about Marcus Wiedower and Jonathan Wallace, the two candidates running for State House District 119.",
      },
      145: {
        image: metaSoloHD145,
        description:
          "Learn more about Quentin Howell and Ricky Williams, the two candidates running for State House District 145.",
      },
      147: {
        image: metaSoloHD147,
        description:
          "Learn more about Stephen Baughier and Heath Clark, the two candidates running for State House District 147.",
      },
      151: {
        image: metaSoloHD151,
        description:
          "Learn more about Gerald Greene and Joyce Barlow, the two candidates running for State House District 151.",
      },
      164: {
        image: metaSoloHD164,
        description:
          "Learn more about Ron Stephens and Marcus Thompson, the two candidates running for State House District 164.",
      },
    },
  },

  targetedRace: {
    house_district_25: { geography_id: "state_house_25" },
    house_district_35: { geography_id: "state_house_35" },
    house_district_44: { geography_id: "state_house_44" },
    house_district_45: { geography_id: "state_house_45" },
    house_district_46: { geography_id: "state_house_46" },
    house_district_48: { geography_id: "state_house_48" },
    house_district_49: { geography_id: "state_house_49" },
    house_district_53: { geography_id: "state_house_53" },
    house_district_64: { geography_id: "state_house_64" },
    house_district_99: { geography_id: "state_house_99" },
    house_district_101: { geography_id: "state_house_101" },
    house_district_103: { geography_id: "state_house_103" },
    house_district_105: { geography_id: "state_house_105" },
    house_district_108: { geography_id: "state_house_108" },
    house_district_117: { geography_id: "state_house_117" },
    house_district_147: { geography_id: "state_house_147" },
    house_district_151: { geography_id: "state_house_151" },
    house_district_154: { geography_id: "state_house_154" },
    house_district_164: { geography_id: "state_house_164" },
    governor: { race_type: "governor" },
    secretary_of_state: { race_type: "secretary_of_state" },
  },
};

export default config;
