import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import "./LanguageSwitcher.styles.scss";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="select-language">
      <select
        value={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
      >
        <option value="en">{!isMobile ? "English" : "En"}</option>
        <option value="es">{!isMobile ? "Español" : "Es"}</option>
        <option value="kr">{!isMobile ? "한국어" : "KR"}</option>
      </select>
    </div>
  );
}
export default LanguageSwitcher;
